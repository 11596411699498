<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
               
              @click="$router.push({name: 'admin.institution-contacts.create'})"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="contacts"
              :sort-mapping="sortMapping"
              :title="'contacts'"
              :total="contactsTotal"
              :fetch-path="'institutions/fetchContacts'"
              :filter-path="'institutions/contactsFilter'"
              :export-path="'institutions/contactsExport'"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit area and group"
                  ><a
                    class="btn btn-icon rounded-circle btn-flat-dark"
                    @click="$router.push({name: 'admin.institution-contacts.edit', params: { id: field.id }})"
                  ><i data-feather="edit-3" /></a></span>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit area and group"
                  ><a
                    class="btn btn-icon rounded-circle btn-flat-dark"
                    :href="$router.resolve({name: 'admin.institution-contacts.edit', params: { id: field.id }}).href"
                    target="_blank"
                  ><i data-feather="external-link" /></a></span>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete area & group"
                    @click="showDeleteModal(field.id)"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                  ><i data-feather="trash-2" /></a></span>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter($event.target.value, 'name')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Position</label>
                  <v-select
                    label="name"
                    :options="positions"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'institutions/fetchPositions')"
                    @input="saveFilter($event, 'position')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Host</label>
                  <v-select
                    label="name"
                    :options="hosts"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'institutions/filter')"
                    @input="saveFilter($event, 'host')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Department</label>
                  <v-select
                    label="name"
                    :options="departments"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'institutions/fetchDepartments')"
                    @input="saveFilter($event, 'department')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Phones</label>
                  <input
                    type="number"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter($event.target.value, 'phones')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Mails</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter($event.target.value, 'mails')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Status</label>
                  <v-select
                    label="name"
                    :options="[
                      {
                        name: 'Active',
                        value: '1',
                      },
                      {
                        name: 'Archived',
                        value: '0',
                      }
                    ]"
                    :get-option-key="option => option.value"
                    item-value="value"
                    @input="saveFilter($event, 'status')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Notes</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter($event.target.value, 'notes')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Department type</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'department_type')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Administration
                    </option>
                    <option value="false">
                      Academic
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Department group</label>
                  <v-select
                    label="name"
                    :clearable="false"
                    :options="departmentTypes"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'department_group')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    vSelect,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Position',
          checked: true,
          order: 2,
        },
        {
          name: 'Title of position',
          checked: true,
          order: 3,
        },
        {
          name: 'Host',
          checked: true,
          order: 4,
        },
        {
          name: 'Department',
          checked: true,
          order: 5,
        },
        {
          name: 'Department unit',
          checked: true,
          order: 6,
        },
        {
          name: 'Department type',
          checked: true,
          order: 7,
        },
        {
          name: 'Phone',
          checked: true,
          order: 8,
        },
        {
          name: 'Mobile phone',
          checked: true,
          order: 9,
        },
        {
          name: 'Email',
          checked: true,
          order: 10,
        },
        {
          name: 'Status',
          checked: true,
          order: 11,
        },
      ],
      sortMapping: {
        Name: 'full_name',
        Position: 'position.name',
        'Title of position': 'position_title',
        Host: 'host.code',
        Department: 'department.name',
        'Department unit': 'department_unit',
        Phone: 'phone',
        'Mobile phone': 'mobile_phone',
        Email: 'email',
        Status: 'status_table',
        'Department type': 'department_type',
      },
      title: 'Contacts',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      contacts: 'institutions/contacts',
      contactsTotal: 'institutions/contactsTotal',
      departments: 'institutions/departments',
      positions: 'institutions/positions',
      hosts: 'institutions/institutions',
      filters: 'filters/filters',
      departmentTypes: 'departmentTypes/items',
    }),
  },
  async mounted() {
    if (this.departmentTypes.length === 0) {
      this.$store.dispatch('departmentTypes/filter', this.$store.getters['filters/filters'])
    }

    if (this.$route.query.host) {
      this.saveFilter({ id: this.$route.query.host }, 'host')
    }
    this.saveFilter({ value: 1 }, 'status')

    await this.$store.dispatch('institutions/contactsFilter', this.filters)
    await this.$store.dispatch('modals/fetchUserFields', 'institutions-contact')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'institutions-contact',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'institutions/fetchContacts', url: `${Vue.prototype.$groupUrl}/institutions/contacts/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
